/* Added slick-theme.css for to change the default styling */

.slick-prev {
  left: -3px;
}
.slick-next {
  right: -3px;
}

.slick-dots li button:before {
  font-size: 8px;
}

.slick-dots li.slick-active button:before {
  opacity: 0.8;
  color: #333;
}
